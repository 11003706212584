import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Mapper } from '../shared/Helpers/helper';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  url: string;

  constructor(private http: HttpClient) {

      this.url = `${environment.apiUrl}Perfil`;
      
 }

 listar(codPerfil:Number = 0){    

  let query = `${this.url}/Listar`;


  return this.http.get(query)
    .pipe(
      map(data=>Mapper(data))
    );
}
}
