import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { LoginComponent } from '../auth/login/login.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path:'',
    // canActivate: [AuthGuard],
    component:HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
