// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keyContratista : "llaveContratist@2022",
  apiKey : " fdbcce6b7f584dc2a601f0c4396b93c7",
  grant_type: "client_credentials",
  client_id: "3ee71956-b546-4289-a3be-c478d0375671",
  apiCuestionarioUrl: "https://apim-dev.saesa.cl/pdenuncias/cuestionario/dev/v1.0",
  apidbUrl: "https://apim-dev.saesa.cl/pdenuncias/controldb/dev/v1.0",
  //apidbUrl: "https://localhost:44352/pdenuncias/controldb/dev/v1.0",

  apiDenunciasPublicaUrl: "https://apim-dev.saesa.cl/pdenuncias/denuncias/dev/v1.0/denuncias/publica",
  apiDenunciasInterna: "https://apim-dev.saesa.cl/pdenuncias/denuncias/dev/v1.0/denuncias/interna",
  //apiDenunciasInterna: "https://localhost:44375/pdenuncias/denuncias/dev/v1.0/denuncias/interna",

  apiConsultaDenunciante: "https://apim-dev.saesa.cl/pdenuncias/denuncias/dev/v1.0/denuncias",
  //apiConsultaDenunciante: "https://localhost:44375/pdenuncias/denuncias/dev/v1.0/denuncias",
  apiCreaDenunciante: "https://apim-dev.saesa.cl/pdenuncias/denuncias/dev/v1.0/denuncias/denunciante",
  //apiCreaDenunciante: "https://localhost:44375/pdenuncias/denuncias/dev/v1.0/denuncias/denunciante",

  client_secret: "RDnBDppmUuq5etTisHvVmvls31GWcbAMzizgpCpjuRw",  
  apiUrl: "https://apim-dev.saesa.cl/pdenuncias/controldb/dev/v1.0",
  //apiUrl: "https://localhost:44352/pdenuncias/controldb/dev/v1.0",

  // apiUrlNew:"https://apim-dev.saesa.cl/pdenuncias/denuncias/dev/v1.0",
  apiJWT: "/oauth2/token",
  denunciante: true,//only tipo-denuncia interna

  clientId_AD: '32f4c096-f63f-4237-acd2-d21f747b9f94',
  authority_AD: 'https://login.microsoftonline.com/136d6ab5-5b55-496f-97cb-2424247715ed/',
  redirectUri_AD: 'https://cdne-pdenuncias-dev-eus-001.azureedge.net/',
  mesesFiltro:24
};
 