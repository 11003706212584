<div class="wrapper"> 

<!-- 
  <div class="preloader flex-column justify-content-center align-items-center">
    <img class="animation__shake" src="assets/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
  </div> -->

<app-navbar></app-navbar>

<app-sidebar></app-sidebar>

<router-outlet></router-outlet>




<!-- 
  <aside class="control-sidebar control-sidebar-dark">

  </aside> -->

</div>
<!-- ./wrapper -->
