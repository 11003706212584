import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ControlesComponent } from './controles/controles.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TablasComponent } from './tablas/tablas.component';

const routes: Routes = [
  {
    path:'controles',
    // canActivate:[AuthGuard],
    component: ControlesComponent
  },
  {
    path:'tablas',
    canActivate:[AuthGuard],
    component: TablasComponent
  },
  {
    path:'dashboard',
    canActivate:[AuthGuard],
    component: DashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ParametrosRoutingModule { }
