import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoModel } from 'src/app/Models/producto.model';
import { TerminoModel } from 'src/app/Models/termino.model';
import { UsuarioMantenedor, UsuarioRol } from 'src/app/Models/usuario.model';
import { ApiService } from 'src/app/services/api.service';
import { ProductoService } from 'src/app/services/productos.service';
import { RutService } from 'src/app/services/rut.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-usuario-modal',
  templateUrl: './usuario-modal.component.html',
  styleUrls: ['./usuario-modal.component.css']
})
export class UsuarioModalComponent implements OnInit {

  @Input() user: UsuarioRol;
  @Input() listaUsuarios: UsuarioRol[];
  form: FormGroup;
  isnew: boolean;
  title: string;
  listaRoles: TerminoModel[] = [];

  constructor(
    private _serviceProducto: ProductoService,
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal,
    public _serviceApi: ApiService,
    public rutservices: RutService,
    private authenticationService: AuthenticationService,
  ) {

    this.crearFormulario();
  }

  ngOnInit(): void {
    //this._serviceApi.getJkt().subscribe(data => {
    this._serviceApi.getRoles(localStorage.getItem('tokenOauth')).subscribe((res) => {
      this.listaRoles = res;
    },
      (err) => {
        console.log(err)
        if (!err.ok) {
          this.authenticationService.logout();
        }
      }
    )
    //});

    if (this.user) {
      this.cargarFormulario();
    }
    else {


      setTimeout(() => {

        $("#telefono").val('')


      },
        100);


    }
  }

  crearFormulario() {
    this.isnew = true;

    this.form = new FormGroup({
      correo: new FormControl("", Validators.email),
      userName: new FormControl("", Validators.required),
      contrasena: new FormControl("", Validators.required),
      //telefono: new FormControl(0, Validators.nullValidator),
      telefono: new FormControl("", Validators.required),
      nombre: new FormControl("", Validators.required),
      apellido: new FormControl("", Validators.required),
      rut: new FormControl("", Validators.required),
      rol: new FormControl("", Validators.required),
    })


  }

  cargarFormulario() {
    this.isnew = false;
    this.form.patchValue({
      correo: this.user.correo,
      userName: this.user.userName,
      contrasena: this.user.contrasena,
      telefono: this.user.telefono,
      nombre: this.user.nombre,
      apellido: this.user.apellido,
      rut: this.user.rut,
      rol: this.user.idRol,
    });

    //this.form.get("contrasena").disable();
    this.form.get("userName").disable();

  }

  guardar() {
    if (this.form.valid) {
      this._alertaService.inicioCarga();
      let item: UsuarioMantenedor = this.form.getRawValue();
      let self = this;

      if (!this.rutservices.validarRutText(item.rut)) {
        return this._alertaService.error("El rut del usuario es invalido.");

      }

      //this._serviceApi.getJkt().subscribe(data => {
      if (this.user) {
        delete item.contrasena;
        this._serviceApi.putUpdateUsuario(localStorage.getItem('tokenOauth'), this.user.idUsuario, item).subscribe(
          (res) => {
            if (res.success) {
              if (this.user.idRol == this.form.get("rol").value) {
                return this._alertaService.exito('Actualizado correctamente', 'OK', function () {
                  self._activeModal.close({ Codigo: 1 });
                });
              }

              this._serviceApi.putUpdateUsuarioRol(localStorage.getItem('tokenOauth'), this.user.idUsuarioRol, { idUsuario: this.user.idUsuario, idRol: this.form.get("rol").value }).subscribe(
                restRol => {

                  if (restRol.success) {
                    this._alertaService.exito('Actualizado correctamente', 'OK', function () {
                      self._activeModal.close({ Codigo: 1 });
                    });
                  } else {
                    this._alertaService.error("El registo no se pudo guardar");
                  }
                },
                (err) => {
                  console.log(err)
                  if (!err.ok) {
                    return this.authenticationService.logout();
                  }
                }
              )

            } else {
              this._alertaService.error("El registo no se pudo guardar");
            }
          },
          (err) => {
            console.log(err)
            if (!err.ok) {
              return this.authenticationService.logout();
            }
          }
        )

      } else {
        let isExist: UsuarioRol[] = this.listaUsuarios.filter(user => user.userName == item.userName);
        if (isExist.length > 0) {
          return this._alertaService.error("Ya existe un usuario " + item.userName);
        }

        //////// si existe user lo actualizo y asocio el rol
        this._serviceApi.existUsuario2(localStorage.getItem('tokenOauth'), item.userName).subscribe(
          (res) => {
            if (res.success) {
              console.log("Ya existe un usuario")
              this._serviceApi.putUpdateUsuario(localStorage.getItem('tokenOauth'), res.result.id, item).subscribe(
                (resactiva) => {
                  console.log("respuesta activacion ", resactiva);

                  if (!resactiva.success) {
                    this._alertaService.error(res.error.message);
                  } else {

                    this._serviceApi.postCreateUsuarioRol(localStorage.getItem('tokenOauth'), { idUsuario: res.result.id, idRol: this.form.get("rol").value }).subscribe(
                      resRol => {
                        console.log(resRol);
                        this._alertaService.exito('Creado correctamente', 'OK', function () {
                          self._activeModal.close({ Codigo: 1 });
                        });
                      },
                      (err) => {
                        console.log(err)
                        if (!err.ok) {
                          return this.authenticationService.logout();
                        }
                      }
                    )
                  }
                },
                (err) => {
                  console.log(err)
                  if (!err.ok) {
                    return this.authenticationService.logout();
                  }
                }
              )
              //////////
              //}             
            } else {
              this._serviceApi.postCreateUsuario(localStorage.getItem('tokenOauth'), item).subscribe(
                (res) => {
                  console.log("respuesta creacion ", res);

                  if (res.success) {
                    this._serviceApi.postCreateUsuarioRol(localStorage.getItem('tokenOauth'), { idUsuario: res.result.id, idRol: this.form.get("rol").value }).subscribe(
                      resRol => {
                        console.log(resRol);
                        this._alertaService.exito('Creado correctamente', 'OK', function () {
                          self._activeModal.close({ Codigo: 1 });
                        });
                      },
                      (err) => {
                        console.log(err)
                        if (!err.ok) {
                          return this.authenticationService.logout();
                        }
                      }
                    )
                  } else {
                    this._alertaService.error(res.error.message);
                  }
                },
                (err) => {
                  console.log(err)
                  if (!err.ok) {
                    return this.authenticationService.logout();
                  }
                }
              )
            }
          })
      }
      //});
    }
    else {
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar() {
    this._activeModal.close({ Codigo: 0 });
  }

}


