import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../Models/usuario.model';
import { environment } from 'src/environments/environment';
import { LoginModel } from '../Models/login.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { RespuestaApi } from '../Models/respuesta.model';
import { Assing } from '../shared/Helpers/helper';
import { DenunciaModel } from '../Models/denuncia.model';

import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';



@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  urlAuth: string;

  constructor(private http: HttpClient, private router: Router, private ADauthService: MsalService) {
    this.urlAuth = `${environment.apiUrl}`;

    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem('token')
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUser = this.currentUserSubject.asObservable();
  }


  get isLoggedIn() {
    //console.log('isLoggedIn', this.currentUserValue);
    // if(this.currentUserValue){
    // if (this.currentUserValue) {
    //    this.loggedIn.next(true);
    //  }
    //   else {
    // this.loggedIn.next(false);
    // }

    console.log('dev');
    this.loggedIn.next(true);

    return this.loggedIn.asObservable();
  }

  public get currentUserValue(): any {
    //console.log("valor current", localStorage.getItem('currentUser'))

    return localStorage.getItem('token');
  }

  validarLogin() {
    if (localStorage.getItem('token')) {
      const helper = new JwtHelperService();

      let token = localStorage.getItem('token');

      const isExpired = helper.isTokenExpired(token);
      if (isExpired) {
        console.log('isExpired', isExpired);
        this.logout();
      } else {
        const decoded = helper.decodeToken(token);
        const expiresAt = moment().add(decoded.expiresIn, 'second');

        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        // this.menus = JSON.parse(this.usuario.Menus);
        // localStorage.setItem("nomenclaturas",this.usuario.Nomenclaturas);
      }
    } else {
      this.logout();
    }
  }

  //  getJkt() {
  //    const formData = new FormData();
  //    formData.append('client_id', environment.client_id);
  //    formData.append('client_secret', environment.client_secret);
  //    formData.append('grant_type', environment.grant_type);
  //    return this.http.post<any>('https://idm.gruposaesa.cl/oauth2/token', formData);
  //  }
  // getJkt2() {
  //   const formData = new FormData();
  //   formData.append('client_id', environment.client_id);
  //   formData.append('client_secret', environment.client_secret);
  //   formData.append('grant_type', environment.grant_type);
  //   return this.http.post<any>('https://idm.gruposaesa.cl/oauth2/token', formData).subscribe((dataToken) => {
  //     console.log("api.service getJkt")
  //     localStorage.setItem('tokenOauth', dataToken.access_token);
  //   });
  // }

  login(login: LoginModel) {
    // login(login: LoginModel, jwt: string) {
    // const headers = {
    //   'x-api-key': environment.apiKey,
    //   Authorization: 'Bearer ' + jwt,
    // };
    let query = `${this.urlAuth}/login`;
    //return this.http.post(query, login, { headers }).pipe(
    return this.http.post(query, login).pipe(
      map((data: any) => data)        
      //,catchError((_) => of('Sin respuesta'))
    );


    // return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
    //     .pipe(map((user:Usuario) => {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('currentUser', JSON.stringify(user));
    //         this.currentUserSubject.next(user);
    //         this.loggedIn.next(true);
    //         return user;
    //     }));

    // let user = new Usuario();
    // user.firstName = "danilo";
    // user.lastName = "Miranda";

    // localStorage.setItem('token', JSON.stringify(user));
    // this.currentUserSubject.next(user);
    // this.loggedIn.next(true);
    // return user;
  }


  logout() {
    // remove user from local storage and set current user to null
    if (localStorage.getItem('loginAd') !== null) {
      this.ADauthService.logout();
    }
    localStorage.removeItem('token');
    localStorage.removeItem('rol');
    localStorage.removeItem('listaUsuarios');
    localStorage.removeItem('userid');
    localStorage.removeItem('username');
    localStorage.removeItem('loginAd');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('listCuestionarios');
    localStorage.removeItem('tokenOauth');   
    localStorage.removeItem('listaRespuesta');
    localStorage.removeItem('tokenExpires'); 
    localStorage.removeItem('tokenTecnova');//ino  
    this.loggedIn.next(false);
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  //se debe crear clase servicio dedicado a denuncias.
  registrarDenuncia(denuncia: DenunciaModel) {
    return this.http.post('ruta de api', denuncia).pipe(
      tap(
        // Log the result or error
        (data) => console.log(data),
        (error) => console.log(error)
      )
    );
  }
}
