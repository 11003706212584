import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductoModel } from 'src/app/Models/producto.model';
import { ProductoService } from 'src/app/services/productos.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-producto-modal',
  templateUrl: './producto-modal.component.html',
  styleUrls: ['./producto-modal.component.css']
})
export class ProductoModalComponent implements OnInit {

  @Input() idProducto:number;

  form: FormGroup;

  constructor(
    private _serviceProducto:ProductoService,
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal,
  ) {

    this.crearFormulario();
    
   }
  cargarFormulario() {
    console.log(this.idProducto);
    
    if(this.idProducto>0){
      this._serviceProducto.obtenerPorId(this.idProducto)
      .subscribe(res=>{
        console.log(res);
        this.form.patchValue({
          Descripcion: res.Descripcion,
          Precio:res.Precio,
          Id:res.Id
        });
        
      })
    }
  }

  crearFormulario() {
    this.form = new FormGroup({
      Id: new FormControl(0),
      Descripcion:new FormControl("", Validators.required),
      Precio: new FormControl("", Validators.required)
    })
  }

  ngOnInit(): void {
    this.cargarFormulario();
  }

  guardar(){
    if(this.form.valid){

      this._alertaService.inicioCarga();

      let item : ProductoModel = this.form.getRawValue();
      console.log("guardar", item);
      this._serviceProducto.guardar(item)
      .subscribe((res:any)=>{
        console.log(res);
        let self = this;
        if (res > 0) {
          this._alertaService.exito('procesado correctamente', 'OK', function(){
            self._activeModal.close({Codigo:1});
          });
        }
        else {
          this._alertaService.error("El registo no se pudo guardar");
        }
      },
      err=>{
        console.log(err);
        this._alertaService.error();
        
      })
    }
    else{
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar(){
    this._activeModal.close({Codigo:0});
  }

}
