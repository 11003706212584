import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertaModel } from '../Models/alerta.model';
import { ApiModel } from '../Models/api.model';
import { ClienteModel } from '../Models/cliente.model';
import { ComunaModel } from '../Models/comuna.model';
import { CuestionarioModel, EncuestaModel, RespuestaModel } from '../Models/cuestionario.model';
import { DenunciaModel } from '../Models/denuncia.model';
import { DenuncianteModel } from '../Models/denunciante.model';
import { EmpleadorModel } from '../Models/empleador.model';
import { EmpresaModel } from '../Models/empresa.model';
import { HurtoModel } from '../Models/hurto.model';
import { JwtModel } from '../Models/jwt.model';
import { TerminoModel } from '../Models/termino.model';
import { Usuario, UsuarioMantenedor, UsuarioRol, ValidaUsuario } from '../Models/usuario.model';
import { ZonaModel } from '../Models/zona.model';
import { Assing, Mapper } from '../shared/Helpers/helper';
import { LoginModel } from '../Models/login.model';



@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) {

  }


  // getJkt() {
  //   //return this.http.post<any>(environment.apidbUrl + '/login/gettoken', '');
  //    const formData = new FormData();
  //    formData.append('client_id', environment.client_id);
  //    formData.append('client_secret', environment.client_secret);
  //    formData.append('grant_type', environment.grant_type);
  //    return this.http.post<any>('https://idm.gruposaesa.cl/oauth2/token', formData);
  // }
  // getJkt2() {
  //   let formData = new FormData();
  //   formData.append('client_id', environment.client_id);
  //   formData.append('client_secret', environment.client_secret);
  //   formData.append('grant_type', environment.grant_type);
  //   //const headers = { 'client_id':environment.client_id,'client_secret':environment.client_secret,'grant_type': environment.grant_type };
  //   return this.http.post<any>('https://idm.gruposaesa.cl/oauth2/token', formData).subscribe((dataToken) => {
  //     console.log("api.service getJkt")
  //     localStorage.setItem('tokenOauth', dataToken.access_token);
  //   });
  // }
  
 
  // Terminos
  getTerminos(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/terminos', { headers }).pipe(map((data) => Mapper<TerminoModel>(data.result.$values)));
  }

  obtenerPorIdTermino(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get(environment.apidbUrl + '/terminos/' + codigo, { headers }).pipe(map((res) => Assing<TerminoModel>(res)));
  }

  putUpdateTermino(jwt: string, codigo: number, data: TerminoModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apidbUrl + '/terminos/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  postCreateTermino(jwt: string, data: TerminoModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apidbUrl + '/terminos', data, { headers }).pipe(map((data: any) => data));
  }

  deleteRemoveTermino(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt, 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova') }
    return this.http.delete(environment.apidbUrl + '/terminos/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }
  // Terminos

  // Roles
  getRoles(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/roles', { headers }).pipe(map((data) => Mapper<TerminoModel>(data.result.$values)));
  }

  // Roles

  // Alertas
  getAlertas(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/alertas', { headers }).pipe(map((data) => Mapper<AlertaModel>(data.result.$values)));
  }

  obtenerPorIdAlerta(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get(environment.apidbUrl + '/alertas/' + codigo, { headers }).pipe(map((res) => Assing<AlertaModel>(res)));
  }

  putUpdateAlerta(jwt: string, codigo: number, data: AlertaModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apidbUrl + '/alertas/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  postCreateAlerta(jwt: string, data: AlertaModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apidbUrl + '/alertas', data, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  deleteRemoveAlerta(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt , 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova')}
    return this.http.delete(environment.apidbUrl + '/alertas/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }
  // alertas

  //UsuariosRoles
  getUsuarioRol(jwt: string, idUser: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/usuariorol/' + idUser, { headers }).pipe(map((data) => Mapper<UsuarioRol>(data.result.$values)));
  }

  getUsuariosRoles(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/usuariorol', { headers }).pipe(map((data) => Mapper<UsuarioRol>(data.result.$values)));
  }

  getRolUsuario(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/usuariorol/' + codigo, { headers }).pipe(map((data: any) => data));
  }

  deleteRemoveUsuarioRol(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt , 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova')}
    return this.http.delete(environment.apidbUrl + '/usuariorol/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  putUpdateUsuarioRol(jwt: string, codigo: number, data: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apidbUrl + '/usuariorol/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  postCreateUsuarioRol(jwt: string, data: any) {
    console.log(data);
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apidbUrl + '/usuariorol', data, { headers }).pipe(map((data: any) => data), catchError((err) => of(err)));
  }
  // UsuariosRoles

  // Usuarios
  getUsuarios(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/usuarios', { headers }).pipe(map((data) => Mapper<UsuarioMantenedor>(data.result.$values)));
  }

  obtenerPorIdUsuario(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get(environment.apidbUrl + '/usuarios/' + codigo, { headers }).pipe(map((res) => Assing<UsuarioMantenedor>(res)));
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async obtenerPorIdUsuario2(codigo: number) {
    //this.getJkt2();
    await this.delay(2000);
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + localStorage.getItem('tokenOauth') }
    return this.http.get(environment.apidbUrl + '/usuarios/' + codigo, { headers }).pipe(map((res) => Assing<UsuarioMantenedor>(res)));
  }

  putUpdateUsuario(jwt: string, codigo: number, data: UsuarioMantenedor) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apidbUrl + '/usuarios/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  postCreateUsuario(jwt: string, data: UsuarioMantenedor) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apidbUrl + '/usuarios', data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  deleteRemoveUsuario(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt , 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova')}
    return this.http.delete(environment.apidbUrl + '/usuariorol/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  existUsuario(jwt: string, user: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get(environment.apidbUrl + '/usuarios/exist/' + user, { headers }).pipe(map((data: any) => data));
  }

  existUsuario2(jwt: string, user: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get(environment.apidbUrl + '/usuarios/existeUsuario/' + user, { headers }).pipe(map((data: any) => data));
  }
  
  deleteUsuario(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt, 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova') }
    return this.http.delete(environment.apidbUrl + '/usuarios/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  postLoginwspronexo(jwt: string, data: any) {
    const headers = {
      'x-api-key': environment.apiKey
      , 'Authorization': 'Bearer ' + jwt
      , 'keyContratista': environment.keyContratista
    }
    return this.http.post(environment.apidbUrl + '/usuarios/loginwspronexo', data, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }
  // Usuarios

  // cuestionarios

  getCuestionarios(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiCuestionarioUrl + '/tipocuestionario', { headers }).pipe(map((data) => Mapper<CuestionarioModel>(data.result.$values)));
  }

  putUpdateCuestionario(jwt: string, codigo: number, data: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apiCuestionarioUrl + '/tipocuestionario/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  postCreateCuestionario(jwt: string, data: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apiCuestionarioUrl + '/tipocuestionario', data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  deleteRemoveCuestionario(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt, 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova') }
    return this.http.delete(environment.apiCuestionarioUrl + '/tipocuestionario/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  // cuestionarios

  // encuestas

  getEncuesta(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiCuestionarioUrl + '/encuesta', { headers }).pipe(map((data) => Mapper<EncuestaModel>(data.result.$values)));
  }

  putUpdateEncuesta(jwt: string, codigo: number, data: EncuestaModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apiCuestionarioUrl + '/encuesta/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  postCreateEncuesta(jwt: string, data: EncuestaModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apiCuestionarioUrl + '/encuesta', data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  deleteRemoveEncuesta(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt, 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova') }
    return this.http.delete(environment.apiCuestionarioUrl + '/encuesta/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  // encuestas

  // respuestas
  getRespuestas(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiCuestionarioUrl + '/tiporespuesta', { headers }).pipe(map((data) => Mapper<RespuestaModel>(data.result.$values)));
  }

  putUpdateRespuesta(jwt: string, codigo: number, data: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.put(environment.apiCuestionarioUrl + '/tiporespuesta/' + codigo, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  postCreateRespuesta(jwt: string, data: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post(environment.apiCuestionarioUrl + '/tiporespuesta', data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
  }

  deleteRemoveRespuesta(jwt: string, codigo: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt, 'TokenTecnova': 'Bearer ' + localStorage.getItem('tokenTecnova') }
    return this.http.delete(environment.apiCuestionarioUrl + '/tiporespuesta/' + codigo, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  // respuestas

  getReportes(jwt: string,fechaInicial: any,fechaFinal: any, rutDenunciante?: string) {
    console.log(rutDenunciante)
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/reportes/' + fechaInicial + '/' + fechaFinal+ '/' + rutDenunciante +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getReportesPago(jwt: string, rut: string, fechaInicial: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/reportes/' + rut + '/' + fechaInicial + '', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getEmpresasDistribuidoras(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/empresadistribuidora', { headers }).pipe(map((data) => Mapper<EmpresaModel>(data.result.$values)));
  }

  getEmpresasComunas(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/comuna', { headers }).pipe(map((data) => Mapper<ComunaModel>(data.result.$values)));
  }

  getTipoDenunciante(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/tipodenunciante', { headers }).pipe(map((data) => Mapper<DenuncianteModel>(data.result.$values)));
  }


  getEmpleador(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/empleador', { headers }).pipe(map((data) => Mapper<EmpleadorModel>(data.result.$values)));
  }


  getHurto(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/tipoHurto', { headers }).pipe(map((data) => Mapper<HurtoModel>(data.result.$values)));
  }

  getZona(jwt: string, empresaId?: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiUrl + '/zona/filtro/' + empresaId, { headers }).pipe(map((data) => Mapper<ZonaModel>(data.result.$values)));
  }

  getZonas(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiUrl + '/zona/', { headers }).pipe(map((data) => Mapper<ZonaModel>(data.result.$values)));
  }

  getZona2(jwt: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/zona', { headers }).pipe(map((data) => Mapper<ZonaModel>(data.result.$values)));
  }

  getNombreZona(jwt: string, zonaId: number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/zona/' + zonaId, { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }


  getCantidadDenuncias(jwt: string,zonaId : number,fechaInicial: any,fechaFinal: any, empresaId:number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/Kpicantdenuingre?idZona=' + zonaId + '&fechaInicio=' + fechaInicial + '&fechaFin=' + fechaFinal +'&idEmpresa=' + empresaId +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getCantidadDenuncias2(jwt: string,zonaId : number,fechaInicial: any,fechaFinal: any, empresaId:number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/kpiproductimetarealcumpl?idZona=' + zonaId + '&fechaInicio=' + fechaInicial + '&fechaFin=' + fechaFinal +'&idEmpresa=' + empresaId +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getDenunciasSegunEstado(jwt: string,zonaId : number,fechaInicial: any,fechaFinal: any, empresaId:number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/kpidenuncsegunestado?idZona=' + zonaId + '&fechaInicio=' + fechaInicial + '&fechaFin=' + fechaFinal +'&idEmpresa=' + empresaId +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getDenunciasSegunEstado2(jwt: string,zonaId : number,fechaInicial: any,fechaFinal: any, empresaId:number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/kpidenusegungestion?idZona=' + zonaId + '&fechaInicio=' + fechaInicial + '&fechaFin=' + fechaFinal +'&idEmpresa=' + empresaId +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getKpiCumplimiento(jwt: string,zonaId : string,fecha: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/Kpidenusegunmetarealcumplimiento?zona=' + zonaId + '&fecha=' + fecha +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getKpiCumplimiento2(jwt: string, zonaId: string, fechaInicial: any, fechaFinal: any, empresaId:number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/Kpidenusegunmetarealcumplimiento2?zona=' + zonaId + '&fechaInicio=' + fechaInicial + '&fechaFin=' + fechaFinal+'&idEmpresa=' + empresaId +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getKpiCumplimiento3(jwt: string,zonaId : string,fecha: any) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/Kpidenusegunmetarealcumplimiento3?zona=' + zonaId + '&fecha=' + fecha +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  getTopDenunciantes(jwt: string,zonaId : number,fechaInicial: any,fechaFinal: any, empresaId:number) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apidbUrl + '/kpi/kpitop10denunciantes?idZona=' + zonaId + '&fechaInicio=' + fechaInicial + '&fechaFin=' + fechaFinal +'&idEmpresa=' + empresaId +'', { headers }).pipe(map((data: any) => data), catchError((_) => of('Sin respuesta')));
  }

  postCreateDenuncia(jwt: string, data: any, type: boolean) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    let url = '';
    if (type) {
      url = environment.apiDenunciasPublicaUrl;
    } else {
      url = environment.apiDenunciasInterna;
    }
    //return this.http.post(url, data, { headers }).pipe(map((data: any) => data), catchError((_) => of(_)));
    return this.http.post(url, data, { headers }).pipe(map((data: any) => data));
  }

  getClientExist(jwt: string, rut: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiConsultaDenunciante + '/' + rut, { headers }).pipe(map((data) => Assing<ClienteModel>(data.result.$values[0])));
  }

  postClientCreate(jwt: string, data: ClienteModel) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.post<any>(environment.apiCreaDenunciante, data, { headers }).pipe(map((data: any) => data));
  }

  getDenuncianteByRut(jwt: string, rut: string) {
    const headers = { 'x-api-key': environment.apiKey, 'Authorization': 'Bearer ' + jwt }
    return this.http.get<any>(environment.apiConsultaDenunciante + '/' + rut, { headers }).pipe(map((data) => (data)));
  }
}
