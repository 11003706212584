import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './shared/components/base/sidebar/sidebar.component';
import { NavbarComponent } from './shared/components/base/navbar/navbar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeModule } from './components/home/home.module';
import { ParametrosModule } from './components/parametros/parametros.module';
import { SeguridadModule } from './components/seguridad/seguridad.module';
import { SecurityInterceptorService } from './shared/services/security-interceptor.service';
import { DataTablesModule } from 'angular-datatables';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './shared/components/base/footer/footer.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { SharedModule } from './shared/shared.module';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { DenunciasComponent } from './components/denuncias/denuncias.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ApiService } from './services/api.service';
import { RutService } from './services/rut.service';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { environment } from 'src/environments/environment';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 0,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId_AD,
      authority: environment.authority_AD,
      redirectUri: environment.redirectUri_AD
    },
    cache: {
      cacheLocation: 'localStorage'
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    DenunciasComponent,        

  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    FormsModule,  
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    PowerBIEmbedModule,
    HomeModule,
    ParametrosModule,
    SeguridadModule,
    SharedModule,
    BrowserModule,
    DataTablesModule,
    NgbModule, 
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 150000, // 15 seconds
      closeButton: true,
      progressBar: true,
    }),
    MsalModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    ApiService,
    RutService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptorService,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
