import { Component, OnInit } from '@angular/core';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { IReportEmbedConfiguration, models, Page, Report, service, VisualDescriptor } from 'powerbi-client';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isEmbedded = false;
  // Overall status message of embedding
  displayMessage = 'The report is bootstrapped. Click Embed Report button to set the access token.';
  reportClass = 'report-container hidden';
  phasedEmbeddingFlag = false;

  reportConfig: IReportEmbedConfiguration = {
    id:"b59dbbbc-2537-4b55-aa14-ab9794b11a7c",
    type: 'report',
    embedUrl: "https://app.powerbi.com/reportEmbed?reportId=b59dbbbc-2537-4b55-aa14-ab9794b11a7c&groupId=cfca77af-0685-4e7e-890c-9f43d2d3afa3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUNFTlRSQUwtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJhbmd1bGFyT25seVJlcG9ydEVtYmVkIjp0cnVlLCJjZXJ0aWZpZWRUZWxlbWV0cnlFbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBab25lUGF0Y2giOnRydWV9fQ%3d%3d",
    tokenType: models.TokenType.Embed,
    accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCIsImtpZCI6Ik1yNS1BVWliZkJpaTdOZDFqQmViYXhib1hXMCJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYzc2N2EzM2EtYzY0Mi00MzIzLWJkOGYtOGNlNDczMjc3M2I3LyIsImlhdCI6MTY0Mzk3OTQwOCwibmJmIjoxNjQzOTc5NDA4LCJleHAiOjE2NDM5ODQ2MTEsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84VEFBQUFUOEdWZlQwUHRXajhhd29wdWpFNTQwaW5KNGcxN2VmT29lTjF5eEpXR0JkTWVQTERlQ0xJc3lVcFJPV0kxbTAvIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMiIsImZhbWlseV9uYW1lIjoiTWlyYW5kYSIsImdpdmVuX25hbWUiOiJEYW5pbG8iLCJpcGFkZHIiOiIxNzAuNzkuMjM1LjEwNyIsIm5hbWUiOiJEYW5pbG8gTWlyYW5kYSIsIm9pZCI6IjBlYzU2NDAxLTdiNTItNDY4NS1hNmU4LWJiYjk3OWYzNDNmNSIsInB1aWQiOiIxMDAzMjAwMEJDQkEwOTc4IiwicmgiOiIwLkFUUUFPcU5ueDBMR0kwTzlqNHprY3lkenR3a0FBQUFBQUFBQXdBQUFBQUFBQUFBMEFCQS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJDQ2Y2YURwT0Y4XzhBTXYyOWdIZ1dHQjBnWmVNUHI5VjZZYmhTMklMWVpRIiwidGlkIjoiYzc2N2EzM2EtYzY0Mi00MzIzLWJkOGYtOGNlNDczMjc3M2I3IiwidW5pcXVlX25hbWUiOiJkYW5pbG8ubWlyYW5kYUB0ZWNub3ZhLmNsIiwidXBuIjoiZGFuaWxvLm1pcmFuZGFAdGVjbm92YS5jbCIsInV0aSI6IjZaMUpLd3VSczAtWDVkT005QUlwQVEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdfQ.ClZ4OQef6PeOmUSLSJkMbiQB_2df_4QthkPOHI0ImQJGzrs7ibg-7uYcxV1xlvf7mS6-zElgCC6mvpayt9fi3Zmk6HCt699AKxHt6hufNJtlUFX5DrwqSgSjphpHrGwih6c6eZWB93-fv1cRO1KvGZquaL2TxQbytYrQ3s9xR0ukje8usGDX2V-tDbZOydFsgLa1yNpF04XcDKe03xqlRjxpxPL4NW1qXPyDA65RHVfjgGc3bLedlQG8ZOTUBSsLvkNMNeRbPFwa0azRh2KlXGjHQB3A-r2i2p4gshbPGcT7n59S2qyb4uUBTbXoQyK5EX7a0og-sMm2C5nFQzmFdg",
    settings: undefined,
  };

  eventHandlersMap  = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report has loaded')],
    [
      'rendered',
      () => {
        console.log('Report has rendered');

        // Set displayMessage to empty when rendered for the first time
        if (!this.isEmbedded) {
          this.displayMessage = 'Use the buttons above to interact with the report using Power BI Client APIs.';
        }

        // Update embed status
        this.isEmbedded = true;
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]);

  constructor() { }

  ngOnInit(): void {
  }

}
