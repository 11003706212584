<div class="card card-secondary" style="width: 600px">
  <div class="card-header">
    <h3 class="card-title">
      {{ title }}
    </h3>
  </div>
  <!-- /.card-header -->
  <!-- form start -->

  <div class="card-body">
    <form [formGroup]="form" class="form">

      <div class="form-group">
        <label for="tipoCuestionario">Tipo de cuestionario</label>
        <select
          class="custom-select"
          formControlName="tipoCuestionario"
          id="tipoCuestionario"
        >
          <option
            *ngFor="let cuestionario of this.listaCuestionarios"
            [ngValue]="cuestionario.id"
          >
            {{ cuestionario.nombre }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="pregunta">Pregunta</label>
        <input
          type="text"
          id="pregunta"
          class="form-control"
          formControlName="pregunta"
        />
      </div>

     
      <div class="form-group">
        <label for="tipoRespuesta">Tipo de respuseta</label>
        <select
          class="custom-select"
          formControlName="tipoRespuesta"
          id="tipoRespuesta"
        >
          <option
            *ngFor="let respuesta of this.listaRespuesta"
            [ngValue]="respuesta.id"
          >
            {{ respuesta.nombre }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="respuesta">Respuesta</label>
        <input
          type="text"
          id="respuesta"
          class="form-control"
          formControlName="respuesta"
        />
      </div>

      <div class="form-group">
        <label for="opciones">Opciones</label>
        <textarea
          type="text-area"
          class="form-control"
          placeholder="Coloque las opciones separadas por (,)"
          id="opciones"
          formControlName="opciones"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="observacion">Observacion</label>
        <input
          type="text"
          id="observacion"
          class="form-control"
          formControlName="observacion"
        />
      </div>

      <div class="form-group" *ngIf="alert">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
            [checked]="alert.isActivo"
          
          />
          <label class="custom-control-label" for="customSwitch1">Activo</label>
        </div>
      </div>

    </form>
   

    <!-- <div [ngClass]="!form.valid? ' callout-danger': ' callout-success'" class="callout">
          <h5> <i *ngIf="!form.valid" class="icon fas fa-ban"></i>
            <i *ngIf="form.valid" class="icon fas fa-check"></i> 
            I am a danger callout!</h5>
          
          <p>{{!form.valid? 'Verifica los datos': 'Los datos están completos'}} </p>
        </div> -->
  </div>
  <!-- /.card-body -->

  <div class="card-footer">
    <button
      type="button"
      class="btn btn-secondary float-right"
      (click)="guardar()"
    >
      Guardar
    </button>
    <button
      type="button"
      class="btn btn-default float-right"
      (click)="cerrar()"
    >
      Cancelar
    </button>
  </div>
</div>
