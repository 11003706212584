
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>Blank Page</h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Blank Page</li>
                </ol>
              </div>
            </div>
          </div><!-- /.container-fluid -->
        </section>
    
        <!-- Main content -->
        <section class="content">
    
          <!-- Default box -->
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Tabla Productos</h3>
    
              <div class="card-tools">
                  <button type="button" class="btn btn-block bg-gradient-secondary" (click)="editar(0)"><i class="fa fa-plus"></i> Nuevo</button>
              </div>
            </div>
            <div class="card-body">
                <table id="eva_datatable" datatable [dtOptions]="_tables.dtOptions" [dtTrigger]="this.dtTrigger">
                    <thead>
                      <tr>
                        <th class="acciones">Acciones</th>
                        <th>Id</th>
                        <th>Descripción</th>
                        <th>Precio</th>
                     
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of lsProductos">
                        <td>
                          <div class="text-center">
                            <i class="fas fa-edit" class="text-primary" (click)="editar(item.Id)"></i>
                            <i class="far fa-trash-alt" class="text-danger" (click)="eliminar(item.Id)"></i>
                           
                          </div>
                        </td>
                        <td>{{item.Id}}</td>
                        <td>{{item.Descripcion}}</td>
                        <td>{{item.Precio | currencyclp }}</td>

                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>
          <!-- /.card -->
    
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->
