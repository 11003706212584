import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertaModel } from 'src/app/Models/alerta.model';
import { ApiService } from 'src/app/services/api.service';
import { ProductoService } from 'src/app/services/productos.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-alerta-modal',
  templateUrl: './alerta-modal.component.html',
  styleUrls: ['./alerta-modal.component.css']
})
export class AlertaModalComponent implements OnInit {

  @Input() alert: AlertaModel;
  @Input() title: string = "Agregar Alerta";
  form: FormGroup;

  constructor(
    private _serviceProducto: ProductoService,
    private _serviceApi: ApiService,
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal
    , private authenticationService: AuthenticationService,
  ) {

    this.crearFormulario();
    //this._serviceApi.getJkt();//ino
  }

  ngOnInit(): void {
    if (this.alert) {
      this.cargarFormulario();

    } else {
      this.crearFormulario();

    }

    setTimeout(() => {
      if (this.alert) {
        $("#customSwitch1").prop("checked", this.alert.isActivo);
      }

    },
      500);
  }

  crearFormulario() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      correoRemite: new FormControl("", Validators.email),
      correoCopiaDestino: new FormControl("", Validators.email),
      correoDestinoCambioEstado: new FormControl("", Validators.email),
      correoDestinoNoCambioEstado: new FormControl("", Validators.email),
      textoCorreo: new FormControl("", Validators.required),
    })
  }

  cargarFormulario() {
    console.log(this.alert);
    this.form.patchValue({
      nombre: this.alert.nombre,
      correoCopiaDestino: this.alert.correoCopiaDestino,
      correoRemite: this.alert.correoRemite,
      correoDestinoCambioEstado: this.alert.correoDestinoCambioEstado,
      correoDestinoNoCambioEstado: this.alert.correoDestinoNoCambioEstado,
      textoCorreo: this.alert.textoCorreo,
    });
  }


  guardar() {
    if (this.form.valid) {

      this._alertaService.inicioCarga();

      let item: AlertaModel = this.form.getRawValue();
      console.log("guardar", item);
      let self = this;

      //this._serviceApi.getJkt().subscribe(data => {

      if (this.alert) {
        // actualizar
        item.isActivo = $('#customSwitch1').is(":checked");
        this._serviceApi.putUpdateAlerta(localStorage.getItem('tokenOauth'), this.alert.id, item).subscribe((res) => {
          console.log("respuesta", res);
          if (res.success) {
            this._alertaService.exito('Actualizado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          (err) => {
            console.log(err)
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
          }
        )
      } else {
        // crear
        item.isActivo = true;
        this._serviceApi.postCreateAlerta(localStorage.getItem('tokenOauth'), item).subscribe((res: any) => {
          if (res.success) {
            console.log("guardar", item);
            this._alertaService.exito('Creado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          err => {
            console.log(err);
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
            this._alertaService.error();
          }
        )

      }

      //})

      // this._serviceProducto.guardarAlerta(item)
      //   .subscribe((res: any) => {
      //     console.log(res);
      //     let self = this;
      //     if (res > 0) {
      //       this._alertaService.exito('procesado correctamente', 'OK', function () {
      //         self._activeModal.close({ Codigo: 1 });
      //       });
      //     }
      //     else {
      //       this._alertaService.error("El registo no se pudo guardar");
      //     }
      //   },
      //     err => {
      //       console.log(err);
      //       this._alertaService.error();

      //     })
    }
    else {
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar() {
    this._activeModal.close({ Codigo: 0 });
  }

}
