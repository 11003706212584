import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { RespuestaModel } from 'src/app/Models/cuestionario.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-respuesta-modal',
  templateUrl: './respuesta-modal.component.html',
  styleUrls: ['./respuesta-modal.component.css']
})
export class RespuestaModalComponent implements OnInit {
  @Input() respuesta: RespuestaModel;
  @Input() title: string = "Agregar Tipo de respuesta";

  form: FormGroup;
  constructor(
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal,
    private _serviceApi: ApiService
    , private authenticationService: AuthenticationService,
  ) {
    this.crearFormulario();
  }

  ngOnInit(): void {
    if (this.respuesta) {
      this.cargarFormulario();
    } else {
      this.crearFormulario();
    }
  }

  crearFormulario() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required)
    })
  }

  cargarFormulario() {
    this.form.patchValue({
      nombre: this.respuesta.nombre,
    });
  }

  guardar() {
    if (this.form.valid) {

      this._alertaService.inicioCarga();

      let item: RespuestaModel = this.form.getRawValue();
      let self = this;

      if (this.respuesta) {
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.putUpdateRespuesta(localStorage.getItem('tokenOauth'), this.respuesta.id, { nombre: item.nombre }).subscribe((res) => {
          if (res.success) {
            this._alertaService.exito('Actualizado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          (err) => {
            console.log(err)
            if (!err.ok) {
              this.cerrar();
              this.authenticationService.logout();
            }
          }
        )
        //})
        // this._serviceApi.putUpdateTermino()
      } else {
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.postCreateRespuesta(localStorage.getItem('tokenOauth'), { nombre: item.nombre }).subscribe((res: any) => {
          if (res.success) {
            this._alertaService.exito('Creado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          err => {
            console.log(err);
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
            this._alertaService.error();
          }
        )
        //})
      }


    } else {
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar() {
    this._activeModal.close({ Codigo: 0 });
  }

}
