import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CuestionarioModel } from 'src/app/Models/cuestionario.model';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Component({
  selector: 'app-cuestionario-modal',
  templateUrl: './cuestionario-modal.component.html',
  styleUrls: ['./cuestionario-modal.component.css']
})
export class CuestionarioModalComponent implements OnInit {

  @Input() cuestionario: CuestionarioModel;
  @Input() title: string = "Agregar Cuestionario";
  form: FormGroup;
  constructor(
    private _alertaService: AlertService,
    public _activeModal: NgbActiveModal,
    private _serviceApi: ApiService
    , private authenticationService: AuthenticationService
  ) {
    this.crearFormulario();
    //this._serviceApi.getJkt();//ino
  }


  ngOnInit(): void {
    if (this.cuestionario) {
      this.cargarFormulario();
    } else {
      this.crearFormulario();
    }
  }

  crearFormulario() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required)
    })
  }

  cargarFormulario() {
    this.form.patchValue({
      nombre: this.cuestionario.nombre,
    });
  }

  guardar() {
    if (this.form.valid) {

      this._alertaService.inicioCarga();

      let item: CuestionarioModel = this.form.getRawValue();
      let self = this;

      if (this.cuestionario) {
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.putUpdateCuestionario(localStorage.getItem('tokenOauth'), this.cuestionario.id, { nombre: item.nombre }).subscribe((res) => {
          if (res.success) {
            this._alertaService.exito('Actualizado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          (err) => {
            console.log(err)
            if (!err.ok) {
              this.cerrar();
              this.authenticationService.logout();
            }
          }
        )
        //})
        // this._serviceApi.putUpdateTermino()
      } else {
        //this._serviceApi.getJkt().subscribe(data => {
        this._serviceApi.postCreateCuestionario(localStorage.getItem('tokenOauth'), { nombre: item.nombre }).subscribe((res: any) => {
          if (res.success) {
            this._alertaService.exito('Creado correctamente', 'OK', function () {
              self._activeModal.close({ Codigo: 1 });
            });
          }
        },
          err => {
            console.log(err);
            if (!err.ok) {
              this.cerrar();
              return this.authenticationService.logout();
            }
            this._alertaService.error();
          }
        )
        //})
      }


    } else {
      this._alertaService.error("Debe completar los campos obligatorios");
    }
  }

  cerrar() {
    this._activeModal.close({ Codigo: 0 });
  }

}
