export function Mapper<T>(items: object): T[] {
    const lista: T[] = [];
    
    Object.keys(items).forEach(key => {
        const item: T = items[key];
        lista.push(item);
    });
    return lista;
}

export function Assing<T>(item): T {
    return Object.assign(<T>{}, item);
}