import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home/home.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    // component: LoginComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./components/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'seguridad',
        loadChildren: () =>
          import('./components/seguridad/seguridad.module').then(
            (m) => m.SeguridadModule
          ),
      },
      {
        path: 'parametros',
        loadChildren: () =>
          import('./components/parametros/parametros.module').then(
            (m) => m.ParametrosModule
          ),
      },
      {
        path: 'proceso',
        loadChildren: () =>
          import('./components/proceso/proceso.module').then(
            (m) => m.ProcesoModule
          ),
      },
      {
        path: 'mantenedor',
        loadChildren: () =>
          import('./components/mantenedor/mantenedor.module').then(
            (m) => m.MantenedorModule
          ),
      },
      {
        path: 'reportes',
        loadChildren: () =>
          import('./components/reportes/reportes.module').then(
            (m) => m.ReportesModule
          ),
      },
      //{path: 'error/:type', component: ErrorPageComponent},
     { path: '', redirectTo: 'index.html', pathMatch: 'full' },
      { path: '**', redirectTo: 'index.html', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
