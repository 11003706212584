import { Component, OnInit } from '@angular/core';
import { PerfilService } from 'src/app/services/perfil.service';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css']
})
export class PerfilesComponent implements OnInit {

  constructor(
    private perfilService: PerfilService
  ) { }

  ngOnInit(): void {
    this.perfilService.listar()
    .subscribe(data=>{
      console.log("perfiles", data);
      
    })
  }

}
