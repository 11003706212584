import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  ok(mensaje = 'procesado correctamente', titulo = 'OK'){    
    Swal.fire({
      icon: 'success',
      title: titulo,
      text: mensaje,
      customClass:{
        confirmButton:'sweetalert-confirmbutton-class'
      }
    });      
  }

  exito(mensaje = 'procesado correctamente', titulo = 'OK', callback){    
    Swal.fire({
      icon: 'success',
      title: titulo,
      text: mensaje,
    }).then((confirmed) => {
      callback(confirmed && confirmed.value == true);
  });    
  }



  alerta(mensaje, titulo = 'ALERTA'){    
    Swal.fire({
      icon: 'warning',
      title: titulo,
      text: mensaje,
    });      
  }

  error(mensaje = 'Parece que algo salió mal', titulo = 'ERROR'){    
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: mensaje,
    });      
  }
  
  info(mensaje, titulo = 'INFO'){    
    Swal.fire({
      icon: 'info',
      title: titulo,
      text: mensaje,
    });      
  }
  
  ayuda(mensaje, titulo = 'AYUDA'){    
    Swal.fire({
      icon: 'question',
      title: titulo,
      text: mensaje,
    });      
  }

  procesando(mensaje:string = 'Procesando información...', titulo:string = 'Espera'){
    Swal.fire({
      title: titulo,
      html: mensaje,      
      timerProgressBar: true,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading()
      }
    })
  }

  descargandoDocumento(mensaje:string = 'Descargado documento...', titulo:string = 'Espera'){
    Swal.fire({
      title: titulo,
      html: mensaje,      
      timerProgressBar: true,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading()
      }
    })
  }
  
  detener(){
    Swal.hideLoading();
  }

  inicioCarga(mensaje:string = 'Cargando...', titulo:string = 'Espera'){
    Swal.fire({
      title: titulo,
      showDenyButton:false,
      showCancelButton:false,
      showConfirmButton:false,
      html: mensaje,      
      timerProgressBar: true,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading()
      }
    })
  }

  finCarga(){
    Swal.hideLoading();
    Swal.close();
  }

  confirmar(titulo, callback){
    Swal.fire({
      title: titulo,
      showCancelButton: true,
      confirmButtonText: `Continuar`,
      customClass:{
        confirmButton:'sweetalert-confirmbutton-class',
        cancelButton:'sweetalert-cancelButton-class'
      }
    }).then((result) => {
      if(result.isConfirmed){
        
      callback();            
      }
    });
  }

  informarcerrar(message:string,title:string, callback) {
    Swal.fire({
      title: title ?? '¿Quieres continuar?',
      text: message,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
    }).then((confirmed) => {
        callback(confirmed && confirmed.value == true);
    });
  }

  informarcerrarOk(message:string,title:string, callback) {
    Swal.fire({
      title: title ?? '¿Quieres continuar?',
      text: message,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
    }).then((confirmed) => {
        callback(confirmed && confirmed.value == true);
    });
  }

  MensajeTareas(message:string,title:string, callback) {
    Swal.fire({
      title: title ?? '¿Quieres continuar?',
      text: message,
      icon: 'info',
      showCancelButton: true,
      cancelButtonText:'Posponer',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Ver Tareas',
      denyButtonText:"No ver mas",
      denyButtonColor:"#d33",
      showDenyButton:true,
      width: 600,
      
    }).then((res) => {
        callback(res);
    });
  }


  mostrarToast(texto){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      icon:"info",
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'info',
      title: !texto?'texto':texto
    })
  }


    
}
